import React, { useContext, useRef, useState } from "react";
import styles from "./signUp.module.css";
import logo from "../../assets/loginPage/logo.png";
import { useNavigate, Link } from "react-router-dom";
import "material-react-toastify/dist/ReactToastify.css";
import eyeIcon from "../../assets/loginPage/eye.png";
import hiddenIcon from "../../assets/loginPage/hidden.png";
import illustration from "../../assets/signupPage/illustration.png";
import { toast } from "material-react-toastify";
import axios from "axios";
import userContext from "../../context/user/userContext";
import isEmail from "validator/es/lib/isEmail";
import OtpModal from "../../components/OtpModal";
import ClipLoader from "react-spinners/ClipLoader";
import envUrls from "../../config";

const SignupPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(false);

  const signUpPasswordRef = useRef();
  const signUpCPasswordRef = useRef();
  const [toggleShowPassword, setToggleShowPassword] = useState(true);
  const [toggleShowCPassword, setToggleShowCPassword] = useState(true);
  const [signUpInProgress, setSignupInProgress] = useState(false);

  // otp modal
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const navigate = useNavigate();

  const showPassword = (ref) => {
    if (ref === signUpPasswordRef) {
      if (toggleShowPassword) {
        ref.current.type = "text";
        setToggleShowPassword(false);
      } else {
        ref.current.type = "password";
        setToggleShowPassword(true);
      }
    } else {
      if (toggleShowCPassword) {
        ref.current.type = "text";
        setToggleShowCPassword(false);
      } else {
        ref.current.type = "password";
        setToggleShowCPassword(true);
      }
    }
  };

  const handleSignUp = () => {
    if (!(name && email && password && cPassword)) {
      toast.error("Fill in all fields to continue", {
        position: "bottom-center",
        autoClose: 5000,
      });
    } else {
      if (!isEmail(email)) {
        toast.error("Enter a valid email", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else if (password !== cPassword) {
        toast.error("Passwords should match", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else if (password.length < 8 || cPassword.length < 8) {
        toast.error("Password should be atlest 8 characters long", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else {
        signUpCall();
      }
    }
  };

  const signUpCall = async () => {
    setSignupInProgress(true);
    const fd = new FormData();
    fd.append("email", email);
    fd.append("password", password);
    fd.append("otp", otp);

    try {
      const response = await axios.post(envUrls.apiUrl + "/register", fd);
      if (response.data.message === "otp sent") {
        toast.success("Verification code sent on your email", {
          position: "bottom-center",
          autoClose: 5000,
        });

        setSignupInProgress(false);
        setOpenOtpModal(true);
      }
      if (
        response.data.message ===
        "user has been already registered, please login."
      ) {
        toast.warning("User has been already registered, please login.", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.section1}>
          <div className={styles.header}>
            <img src={logo} alt="" />
            <h2>Proofify</h2>
          </div>
          <div className={styles.main}>
            <h2>
              Sign <span style={{ color: "#896118" }}>Up</span>
            </h2>
            <h4>
              Already have an account ?{" "}
              <span
                onClick={() => navigate("/")}
                style={{ color: "#896118", cursor: "pointer" }}
              >
                Log in
              </span>{" "}
            </h4>
            <form>
              <label htmlFor="name">Name</label>
              <input
                value={name}
                type="text"
                name="name"
                id="name"
                placeholder="enter your name"
                onChange={(e) => setName(e.target.value)}
              />

              <label htmlFor="email">Email ID</label>
              <input
                value={email}
                type="email"
                name="email"
                id="email"
                placeholder="enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                value={password}
                ref={signUpPasswordRef}
                type="password"
                name="password"
                id="password"
                placeholder="enter your password"
                onChange={(e) => setPassword(e.target.value)}
                style={{ paddingRight: "50px" }}
              />
              <label htmlFor="cPassword">Confirm Password</label>
              <input
                value={cPassword}
                ref={signUpCPasswordRef}
                type="password"
                name="cPassword"
                id="cPassword"
                placeholder="confirm your password"
                onChange={(e) => setCPassword(e.target.value)}
                style={{ paddingRight: "50px" }}
              />
              <img
                onClick={() => {
                  showPassword(signUpPasswordRef);
                }}
                className={styles.eyeIcon}
                src={toggleShowPassword ? eyeIcon : hiddenIcon}
                alt=""
              />
              <img
                onClick={() => {
                  showPassword(signUpCPasswordRef);
                }}
                className={styles.eyeIcon2}
                src={toggleShowCPassword ? eyeIcon : hiddenIcon}
                alt=""
              />
              <button
                onClick={handleSignUp}
                className={styles.signUp}
                type="button"
              >
                Sign Up
                <span style={{ width: "5px" }}></span>
                <ClipLoader
                  color={"#896118"}
                  loading={signUpInProgress}
                  size={20}
                />
              </button>
            </form>
          </div>
        </div>
        <div className={styles.section2}>
          <img src={illustration} alt="" />
        </div>
        {/* {error && <ToastContainer limit={1} />} */}
      </div>
      <OtpModal
        handler={{
          open: openOtpModal,
          close: setOpenOtpModal,
          email,
          password,
        }}
      />
    </>
  );
};

export default SignupPage;
