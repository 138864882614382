import React, { useEffect, useState } from "react";
import styles from "./homePage.module.css";
import logo2 from "../../assets/loginPage/logo-2.png";
import verificationIcon from "../../assets/homePage/Finance_Document.png";
import walletIcon from "../../assets/homePage/Dollar_Account.png";
import { FileUploader } from "react-drag-drop-files";
import { create } from "ipfs-http-client";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";
import Web3 from "web3";
import { loadContract } from "../../utils/load-contract";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "material-react-toastify";
import { Link, useNavigate } from "react-router-dom";
import copyIcon from "../../assets/homePage/Copy.png";
import invalidFileIcon from "../../assets/homePage/invalid-file.png";
import reloadIcon from "../../assets/homePage/Restart.png";
import documentIcon from "../../assets/homePage/document.png";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { Web3Storage } from "web3.storage/dist/bundle.esm.min.js";
import envUrls from "../../config";
const fileTypes = ["DOCX", "JPG", "PDF", "TXT"];
const client = create("https://ipfs.infura.io:5001/api/v0");
const TOKEN_KEY = envUrls.web3StorageToken;

function HomePage() {
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [hash, setHash] = useState(null);
  const [account, setAccount] = useState(null);
  const [meta, setMeta] = useState(false);
  const [stored_hash, getHash] = useState(null);
  const [link, setLink] = useState(null);
  const [gettx, setGettx] = useState(null);
  const [tx, settx] = useState(null);
  const [same, setSame] = useState(false);
  const [hashUploaded, setHashUploaded] = useState(false);
  const [hashRejected, setHashRejected] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingHash, setUploadingHash] = useState(false);
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [pdfBuffer, setPdfBuffer] = useState([]);

  function makeStorageClient() {
    return new Web3Storage({ token: TOKEN_KEY });
  }

  async function storeFiles(files) {
    const client = makeStorageClient();
    files = [files];
    const cid = await client.put(files);
    console.log("stored files with cid:", cid);
    return cid;
  }

  const [web3Api, setWeb3Api] = useState({
    provider: null,
    web3: null,
    contract: null,
  });

  const setAccountListener = (provider) => {
    provider.on("accountsChanged", (accounts) => setAccount(accounts[0]));
  };

  useEffect(() => {
    const getAccount = async () => {
      const accounts = await web3Api.web3.eth.getAccounts();
      setAccount(accounts[0]);
    };
    web3Api.web3 && getAccount();
  }, [web3Api.web3]);

  const handleChange = (file) => {
    setFile(file);
  };

  const handleUpload = async (e) => {
    setGettx(null);
    setUploading(true);
    e.preventDefault();
    setFileUploaded(false);
    try {
      const created = await storeFiles(file);
      const h = `${created}`;
      setHash(h);
      setFileUploaded(true);
      setUploading(false);

      // toast
      toast.success("File uploaded successfully", {
        position: "bottom-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.error(error);
      setUploading(false);

      toast.error("Failed to upload", {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: envUrls.infuraId,
      },
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK,
      options: {
        appName: "Nick's NFT",
        infuraId: envUrls.infuraId,
        rpc: "",
        chainId: 4,
        darkMode: true,
      },
    },
    binancechainwallet: {
      package: true,
    },
  };

  const web3Modal = new Web3Modal({
    network: "rinkeby",
    theme: "dark",
    providerOptions,
  });

  const connect = () => {
    const loadProvider = async () => {
      // const provider = await detectEthereumProvider();
      const provider = await web3Modal.connect();
      const contract = await loadContract("Hash", provider);
      if (provider) {
        setAccountListener(provider);
        provider.request({ method: "eth_requestAccounts" });
        setWeb3Api({
          web3: new Web3(provider),
          provider,
          contract,
        });
        setMeta(true);
      } else {
        console.error("Please install MetaMask!");
        setMeta(false);
        toast.error("Unable to connect Metamask", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    };

    loadProvider();
  };

  const getTheHash = async () => {
    const { contract } = web3Api;
    const x = await contract.getHash({
      from: account,
    });
    getHash(x);
    const ipfsLink = `https://ipfs.infura.io/ipfs/${x}`;
    setLink(ipfsLink);
    setGettx(tx);
  };

  const setTheHash = async () => {
    setUploadingHash(true);
    try {
      const { contract } = web3Api;
      if (stored_hash === hash) {
        setSame(true);
        toast.error("This file is already stored in Blockchain", {
          position: "bottom-center",
          autoClose: 5000,
        });
        setUploadingHash(false);
        return;
      }
      console.log(hash);
      await contract
        .sendHash(hash, {
          from: account,
        })
        .then((result) => settx(result.tx));
      setHashUploaded(true);
      getHash(null);
      setUploadingHash(false);

      toast.success("Hash uploaded in blockchain network", {
        position: "bottom-center",
        autoClose: 5000,
      });
    } catch (error) {
      setHashRejected(true);
      setUploadingHash(false);
      toast.error("Transaction cancelled", {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  const handleCopy = (text) => {
    console.log(">>>", text);
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      toast.success("copied to clipboard", {
        position: "bottom-center",
        autoClose: 5000,
      });
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.navbar}>
          <img src={logo2} alt="" />
          <h2>Proofify</h2>

          <div className={styles.divider}></div>

          <img className={styles.navIcon} src={verificationIcon} alt="" />
          <span>
            <Link to="/verify" target={"_blank"}>
              Verification
            </Link>
          </span>

          <img className={styles.navIcon} src={walletIcon} alt="" />
          <div className={styles.walletStatus}>
            <span onClick={connect}>Connect Wallet</span>
            <span className={styles.userStatus}>
              {account ? "" : "not connected"}
            </span>
          </div>

          <span className={styles.accountAdd}>{account ? account : ""}</span>

          <div className={styles.userBox}>
            <div
              title={sessionStorage.getItem("proofifyUser")}
              className={styles.user}
            >
              {sessionStorage.getItem("proofifyUser")[0].toUpperCase()}
            </div>
            <button
              onClick={() => {
                sessionStorage.removeItem("proofifyUser");
                navigate("/");
              }}
            >
              Logout
            </button>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.fileSection}>
            <div className={styles.fileSectionChild}>
              {!invalidFile ? (
                <div className={styles.uploadSection}>
                  <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    multiple={false}
                    onTypeError={(error) => setInvalidFile(true)}
                  />
                  <button
                    disabled={!file || uploading}
                    onClick={handleUpload}
                    className={styles.upload}
                  >
                    Upload
                    <span style={{ width: "5px" }}></span>
                    <ClipLoader
                      color={"#896118"}
                      loading={uploading}
                      size={20}
                    />
                  </button>
                </div>
              ) : (
                <div className={styles.invalidFile}>
                  <img src={invalidFileIcon} alt="" />
                  <div className={styles.divider2}></div>
                  <div className={styles.invalidInfo}>
                    <p>This file does not seem to be a PDF, TXT or JPG.</p>
                    <button onClick={() => setInvalidFile(false)}>
                      START OVER <img src={reloadIcon} alt="" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ opacity: fileUploaded ? 1 : 0.5 }}
            className={styles.setHashSection}
          >
            <h3>Set the hash in Blockchain network</h3>
            <button
              onClick={setTheHash}
              disabled={!fileUploaded || uploadingHash}
              className={styles.upload}
            >
              Set Hash
              <span style={{ width: "5px" }}></span>
              <ClipLoader color={"#896118"} loading={uploadingHash} size={20} />
            </button>
          </div>
          <div
            style={{ opacity: hashUploaded ? 1 : 0.5 }}
            className={styles.getHashSection}
          >
            <h3>Get the hash from Blockchain network</h3>
            <button
              disabled={!fileUploaded || uploadingHash}
              onClick={getTheHash}
              className={styles.upload}
            >
              Get Hash
            </button>
            <div
              style={{ display: gettx ? "flex" : "none" }}
              className={styles.hashSection}
            >
              <label className={styles.getHashLabels}>Transaction ID</label>
              <input type="text" value={tx} />
              <img
                onClick={() => handleCopy(stored_hash)}
                className={styles.copyIcon2}
                src={copyIcon}
                alt=""
              />

              <label className={styles.getHashLabels}>Content ID</label>
              <input type="text" value={stored_hash} />
              <img
                onClick={() => handleCopy(tx)}
                className={styles.copyIcon1}
                src={copyIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default HomePage;
