const apiUrl = process.env.REACT_APP_API_URL;
const accountPrivateKey = process.env.REACT_APP_ACC1_PRIVATE_KEY
const goerliRpcUrl = process.env.REACT_APP_GOERLI_RPC_URL
const web3StorageToken = process.env.REACT_APP_WEB3STORAGE_API_TOKEN
const mumbaiRpcUrl = process.env.REACT_APP_MUMBAI_RPC_URL
const polygonScanKey = process.env.REACT_APP_POLYGONSCAN_KEY
const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL
const infuraId = process.env.REACT_APP_INFURA_ID

const envUrls = { apiUrl,accountPrivateKey,goerliRpcUrl,web3StorageToken,mumbaiRpcUrl,polygonRpcUrl,polygonScanKey,infuraId };

export default envUrls;