import React, { useContext, useRef, useState } from "react";
import styles from "./loginPage.module.css";
import logo from "../../assets/loginPage/logo.png";
import logo2 from "../../assets/loginPage/logo-2.png";
import sirpiLogo from "../../assets/loginPage/sirpi-logo.png";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import eyeIcon from "../../assets/loginPage/eye.png";
import hiddenIcon from "../../assets/loginPage/hidden.png";
import axios from "axios";
import userContext from "../../context/user/userContext";
import envUrls from "../../config";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const passwordRef = useRef();
  const [toggleShowPassword, setToggleShowPassword] = useState(true);

  const navigate = useNavigate();

  const [userState, dispatch] = useContext(userContext);

  const handleLogin = async () => {
    setError(false);

    const fd = new FormData();
    fd.append("email", email);
    fd.append("password", password);

    try {
      const response = await axios.post(envUrls.apiUrl + "/login", fd);
      if (response.data.message === "logged in successfully") {
        dispatch({ type: "VALID-USER" });

        toast.success("Logged In successfully", {
          position: "bottom-center",
          autoClose: 5000,
        });
        sessionStorage.setItem("proofifyUser", email);
        navigate("/home");
      } else {
        toast.error("Enter valid credentials", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
      });
      setError(true);
    }
  };

  const showPassword = () => {
    if (toggleShowPassword) {
      passwordRef.current.type = "text";
      setToggleShowPassword(false);
    } else {
      passwordRef.current.type = "password";
      setToggleShowPassword(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.section1}>
        <div className={styles.header}>
          <img src={logo} alt="" />
          <h2>Proofify</h2>
        </div>
        <div className={styles.verify}>
          <button onClick={() => navigate("/verify")}>
            # Click here, to verify the hash
          </button>
        </div>
        <div className={styles.divider}>
          <div></div>
          <span>Or log in</span>
          <div></div>
        </div>
        <div className={styles.main}>
          <h2>
            Log <span style={{ color: "#896118" }}>In</span>
          </h2>
          <form>
            <label htmlFor="email">Email</label>
            <input
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder="enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="password">Password</label>
            <input
              value={password}
              ref={passwordRef}
              type="password"
              name="password"
              id="password"
              placeholder="enter your password"
              onChange={(e) => setPassword(e.target.value)}
              style={{ paddingRight: "50px" }}
            />
            <span className={styles.forgotPassword}>Forgot Password?</span>
            <img
              onClick={() => {
                showPassword();
              }}
              className={styles.eyeIcon}
              src={toggleShowPassword ? eyeIcon : hiddenIcon}
              alt=""
            />
            <button type="button" onClick={handleLogin}>
              Log In
            </button>
          </form>
          <p>
            Don’t have an account? <Link to="/signup">Create</Link>
          </p>
        </div>
        {/* <div className={styles.footer}>
          <p>Powered By</p>
          <img src={sirpiLogo} alt="" />
        </div> */}
      </div>
      <div className={styles.section2}>
        <img src={logo2} alt="" />
        <span>Proofify</span>
      </div>
      {/* {error && <ToastContainer limit={1} />} */}
    </div>
  );
}

export default LoginPage;
